import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { IUpgradeRule } from './createUpgradeRule';
import { IAttachment } from './editProductPlan';
import { IProduct } from './getProducts';

export enum PLAN_RECURRING_CHARGE_TYPE {
  DEFAULT = 'default',
  STRICT_FROM_PLAN_PRICE = 'strict-from-plan-price',
  STRICT_FROM_PLAN_PRICE_UNTIL_EXPANDS = 'strict-from-plan-price-until-expands',
  STRICT_FROM_PLAN_PRICE_UNTIL_EXPANDS_BASED_ON_SERVICE_LIST = 'strict-from-plan-price-until-expands-based-on-service-list',
}

export interface IUpgradeRuleItem extends IUpgradeRule {
  id: string;
}

export interface IPlan {
  availableCountryCodes?: string[];
  regionalPlans: Array<any>;
  isRegional: boolean;
  countryCodes: string[];
  additionalInfo: null | string;
  adjustPrice: number;
  adjustRecurringPrice: number;
  autopayable: boolean;
  membershipDiscountAllowed: boolean;
  rewardingDays: number;
  bgColor: null | string;
  bullets: null | Array<any>;
  color: null | string;
  comboData: null | string;
  countries: string[];
  currency: 'USD' | 'EUR';
  discountExigoItem: null | string;
  fsbEnabled: boolean;
  fullDescription: null | string;
  intervalSize: null | number;
  intervalUnit: null | string;
  isAddon: boolean;
  itemDescription: null | string;
  planGroupId: number;
  priority: number;
  product?: IProduct;
  createdAt?: Date;
  description: null | string;
  id: number | null;
  imageUrl: string;
  name: string;
  recurringType: string;
  titleDescription: string;
  questDollarsReward: number;
  rebillyPlanId: string;
  recurringPrice: number;
  recurringRetailPrice: number;
  recurringSKU: string;
  pointRewards: {
    cv: number;
    fsb: number;
    qv: number;
    scv: number;
    sqv: number;
    rcv: number;
    rfsb: number;
    rqv: number;
    rscv: number;
    rsqv: number;
    matrixBV: number;
  };
  regionalDatasets: Array<any>;
  retailExigoItem: null | string;
  retailPrice: number;
  setupPrice: number;
  sku: string;
  updatedAt?: Date;
  upgradeRuleFrom: [];
  upgradeRuleTo: [];
  oneDayRecurringSKU: string;
  comboName: string;
  comboExigoItem: string;
  comboDiscountCV: number;
  comboDiscountQV: number;
  comboDiscountSCV: number;
  comboDiscountSQV: number;
  comboDiscountFSB: number;
  comboDiscount: number;
  comboRetailDiscount: number;
  isRecurring: boolean;
  recurringChargeType: PLAN_RECURRING_CHARGE_TYPE;
  hasRetail: boolean;
  noExigo: boolean;
  status: string;
  requireVerification: boolean;
  tLevelJoin: boolean;
  isSage: boolean;
  attachments: IAttachment[];
  serviceIDs: number[];
  requireAgreement: boolean;
  enrollerCashback: number;
  customerCashback: number;
  stripeCustomPlanId: string | null;
  trialPeriod?: number | null;
  primaryServiceId: string | number | null;
  splitRenewPeriod: null | number;
  splitRenewValues: null | number;
}

export interface IProductWithPlans {
  createdAt: string;
  description: string;
  id: number;
  imageUrl: string;
  name: string;
  plans: IPlan[];
  updatedAt: string;
  upgradeRules: IUpgradeRuleItem[];
  bgColor: string;
  color: string;
  fullImageUrl: string;
  priority: number;
  rebillyProductId: string;
  urlParam: string;
  status: string;
}

const request = (productId: string) =>
  apiClient
    .get<IResponseAxios<IProductWithPlans>>(ApiUrls.baseProduct + productId)
    .then((response) => response);

export default request;

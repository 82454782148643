import { Box, Button, Grid } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import switchingRuleRequests, {
  ISwitchingLocalRules,
  ISwitchingRule,
} from '../../services/api/requests/switchingRules';
import { IOption } from '../../utils/commonTypes';
import { normalizeRulesList } from '../../utils/helpers';
import setNotification from '../../utils/notifications';
import Can, { Permissions } from '../Can';
import CreateEditSwitchingRule from '../CreateEditSwitchingRule';
import SwitchingRuleItem from '../SwitchingRuleItem';
import Title from '../Title';

interface ISwitchingRules {
  planId: number;
  services: IOption[];
  countries: string[];
}

const SwitchingRulesList: FC<ISwitchingRules> = ({ planId, services, countries }) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRule, setSelectedRule] = useState<ISwitchingRule>();
  const [showCreateRulePanel, togglePanel] = useState<boolean>(false);
  const [list, setList] = useState<ISwitchingLocalRules[] | undefined>(undefined);

  const fetchSwitchingRulesList = useCallback(async () => {
    try {
      const response = await switchingRuleRequests.readSwitchingRules({
        planId,
        page: 1,
        limit: 100,
      });

      setList(normalizeRulesList(response?.data?.data, services));
    } catch (error) {
      console.error('Error: ', error);
    } finally {
      setLoading(false);
    }
  }, [planId, services]);

  const handleDeleteRule = async (id: number) => {
    try {
      await switchingRuleRequests.deleteSwitchingRule(id);
      setList((prev) => prev?.filter((item) => item.id !== id));
      setNotification('success', {
        message: 'Success',
      });
    } catch (error) {
      console.error('Error: ', error);
    }
  };
  const onSubmit = () => {
    closeEditRule();
    fetchSwitchingRulesList().then(console.log);
  };

  const openEditRule = (rule: ISwitchingRule) => {
    setSelectedRule(rule);
  };
  const openCreateRule = () => {
    togglePanel(true);
  };

  const closeEditRule = () => {
    setSelectedRule(undefined);
  };

  useEffect(() => {
    if (!list) {
      if (!loading) {
        setLoading(true);
      }
      fetchSwitchingRulesList().then(console.log);
    }
  }, [fetchSwitchingRulesList, list, loading]);

  useEffect(() => {
    if (!!selectedRule) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedRule]);

  const [mainRules, regionalRules] = useMemo(() => {
    const mainRules = [];
    const regionalRules = [];

    if (list?.length) {
      for (let i = 0; i < list?.length; i++) {
        if (list[i]?.countryCodes?.length) {
          regionalRules.push(list[i]);
        } else {
          mainRules.push(list[i]);
        }
      }
    }
    return [mainRules, regionalRules];
  }, [list]);

  return (
    <>
      <Grid container xs={12}>
        <Box margin={'20px 0 0 0'} width="100%">
          <Title>{`${regionalRules.length ? 'General' : ''} Switching Rules`} </Title>
        </Box>
      </Grid>

      <Grid container xs={12}>
        <Box display={'flex'} width={'100%'} flexWrap={'wrap'} justifyContent="space-between">
          {mainRules?.map((rule) => (
            <SwitchingRuleItem
              key={rule.id}
              rule={rule}
              openEditRule={() => openEditRule(rule)}
              handleDeleteRule={() => handleDeleteRule(rule.id)}
            />
          ))}

          {!!regionalRules?.length && (
            <Grid container xs={12}>
              <Box margin={'20px 0 0 0'} width="100%">
                <Title>Regional Switching Rules </Title>
              </Box>
            </Grid>
          )}

          {regionalRules?.map((rule) => (
            <SwitchingRuleItem
              rule={rule}
              openEditRule={() => openEditRule(rule)}
              handleDeleteRule={() => handleDeleteRule(rule.id)}
            />
          ))}

          {showCreateRulePanel || selectedRule ? (
            <CreateEditSwitchingRule
              onCancelClick={closeEditRule}
              ruleForEditing={selectedRule}
              existedList={list}
              onSuccess={onSubmit}
              availableCountries={countries}
            />
          ) : (
            <Can perform={Permissions.createSwitchingRules}>
              <Box
                width={'100%'}
                justifyContent="space-between"
                alignItems={'center'}
                padding={'20px 0 0 0'}
                onClick={openCreateRule}
              >
                <Button fullWidth variant="outlined" color="primary">
                  + Add Rule
                </Button>
              </Box>
            </Can>
          )}
          <div ref={scrollRef} />
        </Box>
      </Grid>
    </>
  );
};

export default SwitchingRulesList;

import { Box, FormControlLabel, Grid, Switch } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from '@root/components/CreateEditProduct/group-item-styles';
import { getNameWithRecurringType } from '@root/components/CreateEditProduct/helpers';
import { PlansGroupFilters } from '@root/components/CreateEditProduct/plan-group-filters';
import { FilterToggle } from '@root/components/FilterToggle';
import 'date-fns';
import * as React from 'react';
import CountriesModal from './countries-modal';
import PlanGroupItem from './plan-group-item';
import { usePlanController } from './use-plan-controller';

const PlanGroups: React.FC<{}> = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [
    { planCountries, groupList, filterState, groupItemState, isGrid },
    {
      createPlan,
      setIsGrid,
      handleDeletePlan,
      handleEditPlan,
      handlePlanStatusChange,
      showPlanCountries,
      groupItemHandlers,
      filterHandlers,
    },
  ] = usePlanController();

  const handleChange = (panel: string) => (_: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const noPlansCreated = !groupList[0]?.length && !groupList[1]?.length;

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <FormControlLabel
          label="Grid"
          control={<Switch checked={isGrid} onChange={() => setIsGrid(!isGrid)} color="primary" />}
        />
        <FilterToggle onFilterClick={filterHandlers.toggleFilterPanel} />
      </Box>
      {filterState.isFilterPanelOpen && (
        <PlansGroupFilters filterState={filterState} filterHandlers={filterHandlers} />
      )}
      {/* {groupItemState.selectedItems.length > 0 && (
        <Paper className={classes.multiselectActionsBlock}>
          <Grid container alignItems="center" direction="row" wrap="nowrap">
            <Box className={classes.selectedItemsText}>
              <Typography>{groupItemState.selectedItems.length} selected</Typography>
            </Box>
            <MultipleChoiceControlModal
              groupItemState={groupItemState}
              groupItemHandlers={groupItemHandlers}
            />
          </Grid>
        </Paper>
      )} */}
      <Grid item xs={12}>
        <div className={classes.root}>
          {groupList.map((item) =>
            item?.map((group) => (
              <Accordion
                key={group[0]?.planGroupId}
                expanded={expanded === `panel${group[0]?.planGroupId}`}
                onChange={handleChange(`panel${group[0]?.planGroupId}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>
                    {getNameWithRecurringType(group[0]?.name, group[0]?.recurringType)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box gridGap={16} width="100%" display="flex" flexDirection="column">
                    <Grid
                      container
                      wrap={isGrid ? 'wrap' : 'nowrap'}
                      direction={isGrid ? 'row' : 'column'}
                      spacing={2}
                    >
                      {group.map((plan) => (
                        <PlanGroupItem
                          key={plan.id}
                          plan={plan}
                          isGrid={isGrid}
                          groupItemState={groupItemState}
                          groupItemHandlers={groupItemHandlers}
                          handleDeletePlan={handleDeletePlan}
                          handleEditPlan={handleEditPlan}
                          handlePlanStatusChange={handlePlanStatusChange}
                          showPlanCountries={showPlanCountries}
                        />
                      ))}
                    </Grid>
                    <Button
                      type="button"
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={() => createPlan(group[0]?.planGroupId)}
                    >
                      + Create Regional Bundle
                    </Button>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))
          )}

          <Box mt="16px" maxWidth={noPlansCreated ? 600 : undefined}>
            <Button
              type="button"
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => createPlan()}
            >
              + Create Bundle
            </Button>
          </Box>
        </div>

        <CountriesModal list={planCountries} onClose={() => showPlanCountries(null)} />
      </Grid>
    </>
  );
};

export default PlanGroups;

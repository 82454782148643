import { ProductContext } from '@root/components/CreateEditProduct/product-context';
import { initialValues } from '@root/components/CreateEditProduct/static-data';
import { IFormValues } from '@root/components/CreateEditProduct/types';
import { createValidationSchema } from '@root/components/CreateEditProduct/validation';
import createProduct from '@root/services/api/requests/createProduct';
import editProductAxios from '@root/services/api/requests/editProduct';
import { selectProducts } from "@root/store/entities/selectors";
import { modifyQueryParams } from '@root/utils/helpers';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useFormHandler = () => {
  const dispatch = useDispatch();
  const { product } = useContext(ProductContext);
  const isEdit = !!product;
  const products = useSelector(selectProducts);

  const editableData = product && {
    name: product?.name as string,
    description: (product?.description as string) || '',
    rebillyProductId: product?.rebillyProductId as string,
    priority: product?.priority || 0,
    color: product?.color,
    bgColor: product?.bgColor,
    status: product?.status || '',
    urlParam: product?.urlParam,
    fullImageUrl: product?.fullImageUrl,
    imageUrl: product?.imageUrl,
  };

  const fk: FormikProps<IFormValues> = useFormik({
    initialValues: editableData || initialValues,
    validationSchema: createValidationSchema(!isEdit ? products : null),
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values: IFormValues) => {
      if (isEdit) {
        return editProductAxios({ ...values, name: values.name.trim(), id: product?.id });
      }
      try {
        const response = await createProduct({ ...values, name: values.name.trim() });
        dispatch(push(modifyQueryParams('productId', response.data.id)));
      } catch (e) {
        console.error('ON_CREATE_PRODUCT', e);
      }
    },
  });

  return { fk, isEdit };
};

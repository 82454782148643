import { Box, Button, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RecurringTypeSelect from '@root/components/RecurringTypeSelect';
import { RECURRING_TYPES } from '@root/enums/recurring-type';
import { FieldArray, FormikProps, FormikProvider } from 'formik';
import { get } from 'lodash';
import { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IDataSet } from '../../services/api/requests/createProductPlan';
import { ICountry } from '../../services/api/requests/getInstances';
import { selectCountries, selectMembershipLevelForEditing } from '../../store/entities/selectors';
import CountrySelect from '../CountrySelect';
import { useStandardFormStyles } from '../FormBase';
import { emptySettings } from './static-data';
import { IValues } from './types';
import { useForm } from './use-form';

const MembershipRegionalSettings: FC<{ membershipId: number; parentId: number | null }> = ({
  membershipId,
  parentId,
}) => {
  const classes = useStandardFormStyles();
  const scrollToRef = useRef<HTMLDivElement>(null);

  const countries: ICountry[] = useSelector(selectCountries);
  const selectedMembershipLevel = useSelector(selectMembershipLevelForEditing);

  const [
    { fk, recurringTypes },
    {
      setSelectedRecurringTypes,
      handleSetRecurringTypes,
      handleSubmit,
      handleDelete,
      handleSplitRenewFieldsChange,
    },
  ] = useForm(membershipId, parentId);
  const usedCountryCodes = fk.values?.regionalSettings?.flatMap((item) => item.countryCodes);
  const availableCountries = countries
    .filter((item) => !usedCountryCodes?.includes(item.code))
    .map((item) => item.code);

  useEffect(() => {
    if (scrollToRef.current) {
      const elementPosition = scrollToRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - 100;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [scrollToRef.current]);

  return (
    <div className={classes.formContainer}>
      <form className={classes.form} onSubmit={fk.handleSubmit}>
        <Grid item xs={12}>
          <Typography color="primary"> Difference by Instance/Country</Typography>
          <FormikProvider value={fk}>
            <FieldArray name="regionalSettings">
              {({ remove, push }) => (
                <>
                  {fk.values?.regionalSettings?.length > 0 &&
                    (fk.values.regionalSettings as IDataSet[]).map(
                      (item: IDataSet, index: number) => (
                        <Grid
                          ref={item.id === selectedMembershipLevel?.id ? scrollToRef : null}
                          container
                          spacing={2}
                          className={classes.bordered}
                        >
                          <Grid item xs={12}>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              <Typography>Regional Dataset Item</Typography>

                              {!fk.values.regionalSettings[index].id && (
                                <IconButton
                                  aria-label="delete"
                                  title={'Delete'}
                                  onClick={() => remove(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Box>

                            <Grid item xs={12}>
                              <Box ml={1} mt={2} mb={1} />
                              <RecurringTypeSelect
                                value={recurringTypes[index] || []}
                                onChange={handleSetRecurringTypes(index)}
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <CountrySelect
                              id={`regionalSettings.${index}.countryCodes`}
                              availableOptions={availableCountries as string[]}
                              value={fk.values.regionalSettings[index].countryCodes || []}
                              onChange={fk.setFieldValue}
                              required
                              withGroups
                            />
                          </Grid>

                          {recurringTypes[index]?.map((type) => {
                            return createRegionalSettingsFields(
                              fk,
                              type,
                              index,
                              handleSplitRenewFieldsChange
                            );
                          })}

                          <Grid item xs={12}>
                            <Box display="flex">
                              {!!fk.values.regionalSettings[index].id && (
                                <>
                                  <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}
                                    fullWidth
                                    onClick={() =>
                                      handleDelete(fk.values.regionalSettings[index].id)
                                    }
                                  >
                                    Delete
                                  </Button>
                                  <Box p={2} />
                                </>
                              )}

                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={
                                  !fk.isValid ||
                                  fk.values.regionalSettings[index].countryCodes?.length === 0
                                }
                                fullWidth
                                onClick={() =>
                                  handleSubmit(fk.values.regionalSettings[index], index)
                                }
                              >
                                Save Settings
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      )
                    )}

                  <Button
                    type={'button'}
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setSelectedRecurringTypes((prev) => [...prev, []]);
                      push(emptySettings);
                    }}
                  >
                    Create
                  </Button>
                </>
              )}
            </FieldArray>
          </FormikProvider>
        </Grid>
      </form>
    </div>
  );
};

export default MembershipRegionalSettings;

const createRegionalSettingsFields = (
  fk: FormikProps<IValues>,
  type: string,
  index: number,
  handleSplitRenewFieldsChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: RECURRING_TYPES,
    index: number
  ) => void
) => {
  if (fk.values.regionalSettings[index]) {
    return (
      <>
        <Grid item xs={12}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography>{type}:</Typography>
          </Box>
        </Grid>
        {[
          `splitBaseRenewValues.${type}`,
          `recurringPrices.${type}`,
          `spendings.${type}.min`,
          `spendings.${type}.max`,
          `pointRewards.${type}.rcv`,
          `pointRewards.${type}.rqv`,
          `pointRewards.${type}.rscv`,
          `pointRewards.${type}.rsqv`,
          `pointRewards.${type}.rfsb`,
          `pointRewards.${type}.rmatrixBV`,
        ].map((item) => {
          const itemArray = item.split('.');
          let label = itemArray[itemArray.length - 1];
          if (itemArray.length === 2) {
            label = itemArray[0]
              .replace('splitBaseRenewValues', 'Split Base Renew Value')
              .replace('recurringPrices', 'Recurring Price');
          }
          return (
            <>
              <Grid item xs={12} md={6} direction={'row'} alignItems={'center'}>
                <TextField
                  key={item}
                  variant="outlined"
                  type={'number'}
                  fullWidth
                  id={`regionalSettings.${index}.${item}`}
                  label={label}
                  required
                  name={`regionalSettings.${index}.${item}`}
                  autoComplete="new-password"
                  onBlur={fk.handleBlur}
                  value={get(fk.values.regionalSettings[index], item)}
                  onChange={fk.handleChange}
                />
              </Grid>
            </>
          );
        })}
        {type !== RECURRING_TYPES.MONTHLY && (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                type="number"
                fullWidth
                label="Split Renew Values"
                name="splitRenewValues"
                onBlur={fk.handleBlur}
                onChange={(e) => handleSplitRenewFieldsChange(e, type as RECURRING_TYPES, index)}
                value={
                  fk.values.regionalSettings[index].splitRenewValues?.[type as RECURRING_TYPES] ||
                  ''
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                type="number"
                fullWidth
                label="Split Renew Periods"
                name="splitRenewPeriods"
                onBlur={fk.handleBlur}
                onChange={(e) => handleSplitRenewFieldsChange(e, type as RECURRING_TYPES, index)}
                value={
                  fk.values.regionalSettings[index].splitRenewPeriods?.[type as RECURRING_TYPES] ||
                  ''
                }
              />
            </Grid>
          </>
        )}
      </>
    );
  }
};

import { IValues } from './types';

export const initialValues: IValues = {
  regionalSettings: [],
};

// export const emptySettings = {
//   countryCodes: [],
//   Monthly: {
//     recurringPrice: 0,
//     splitBaseRenewValue: 0,
//     spendings: {
//       min: 0,
//       max: 0,
//     },
//     pointRewards: {
//       rcv: 0,
//       rqv: 0,
//       rscv: 0,
//       rsqv: 0,
//       rfsb: 0,
//       rmatrixBV: 0,
//     },
//   },
//   Annual: {
//     splitBaseRenewValue: 0,
//     recurringPrice: 0,
//     spendings: {
//       min: 0,
//       max: 0,
//     },
//     pointRewards: {
//       rcv: 0,
//       rqv: 0,
//       rscv: 0,
//       rsqv: 0,
//       rfsb: 0,
//       rmatrixBV: 0,
//     },
//   },
// };

export const emptySettings = {
  spendings: {},
  recurringPrices: {},
  pointRewards: {},
  splitBaseRenewValues: {},
  countryCodes: [],
  splitRenewValues: {},
  splitRenewPeriods: {},
};

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import RecurringTypeSelect from '@root/components/RecurringTypeSelect';
import { RECURRING_TYPES } from '@root/enums/recurring-type';
import { push } from 'connected-react-router';
import 'date-fns';
import { FormikProps } from 'formik';
import { get } from 'lodash';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../../components/BackButton';
import FileInput from '../../components/FileInput';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import Title from '../../components/Title';
import { IMAGE_UPLOAD_ENTITY } from '../../constants';
import getProducts from '../../services/api/requests/getProducts';
import { editMembershipLevelData } from '../../store/entities/actions';
import { selectMembershipLevelForEditing } from '../../store/entities/selectors';
import pageLinks from '../../utils/pageLinks';
import MembershipRegionalSettings from '../MembershipRegionalSettings';
import { IValues } from './types';
import { useMembershipLevelFormik } from './use-formik';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

const CreateEditMembershipLevel: React.FC = () => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();
  const selectedMembershipLevel = useSelector(selectMembershipLevelForEditing);
  const actionText = selectedMembershipLevel ? 'Edit' : 'Create';
  const [planList, setPlanList] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const { fk, recurringTypes, handleRecurringTypeChange, handleSplitRenewFieldsChange } =
    useMembershipLevelFormik(selectedMembershipLevel);

  const planOptions = React.useMemo(() => {
    return planList.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }, [planList]);

  const fetchPlanList = async () => {
    try {
      const response = await getProducts();

      const planList = response.data.data
        .flatMap((item) => item.plans)
        .filter((plan) => plan.rebillyPlanIds.includes('t-level-usd'));

      setPlanList(planList);
    } catch (e) {
      console.warn(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchPlanList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect((): any => {
    return () => dispatch(editMembershipLevelData(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createInputField = createInput<IValues>(fk);

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.membershipSettings} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{`${actionText} Membership Level`}</Title>
        <CssBaseline />
        {loading && (
          <Box alignItems={'center'} justifyContent={'center'} my={8} mx="auto">
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <div className={classes.formContainer}>
            <form className={classes.form} onSubmit={fk.handleSubmit}>
              <Grid container spacing={2}>
                {createInputField('name', 'Name', 6)}
                {createInputField('memberName', 'Member Name', 6)}
                {createInputField('priority', 'Priority', 6, 'number')}
                {createInputField(
                  'color',
                  'Color',
                  6,
                  'color',
                  false,
                  (e) => {
                    if (e?.target?.value) {
                      fk.setFieldValue('color', e.target.value);
                    }
                  },
                  false,
                  '',
                  fk.values.color || '#000'
                )}
                <Grid item xs={6}>
                  <FileInput
                    id={'imageUrl'}
                    label={'Image'}
                    imageUrl={fk.values.imageUrl}
                    imageUrlField={'imageUrl'}
                    fk={fk}
                    entity={IMAGE_UPLOAD_ENTITY.MEMBERSHIP_LEVEL}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FileInput
                    id={'iconUrl'}
                    label={'Icon'}
                    imageUrl={fk.values.iconUrl}
                    imageUrlField={'iconUrl'}
                    fk={fk}
                    entity={IMAGE_UPLOAD_ENTITY.MEMBERSHIP_LEVEL}
                  />
                </Grid>
                {createInputField(
                  'addonsDiscount',
                  'Addons Discount',
                  12,
                  'number',
                  true,
                  fk.handleChange,
                  false,
                  '%'
                )}
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Related plans</InputLabel>
                    <Select
                      id="planIds"
                      label={'Plans'}
                      variant="outlined"
                      multiple
                      value={fk?.values?.planIds || []}
                      onChange={(e) => fk.setFieldValue('planIds', e.target.value)}
                      input={<Input />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                      MenuProps={MenuProps}
                    >
                      {planOptions.map(({ value, label }) => (
                        <MenuItem className={classes.textWrap} key={label} value={value}>
                          <Checkbox checked={fk?.values?.planIds?.includes(value)} />
                          <ListItemText primary={label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box ml={1} mt={2} mb={1}>
                    Recurring Types
                  </Box>
                  <RecurringTypeSelect
                    value={recurringTypes}
                    onChange={handleRecurringTypeChange}
                  />
                </Grid>

                {recurringTypes.map((type) =>
                  createRegionalSettingsFields(fk, type, handleSplitRenewFieldsChange)
                )}
              </Grid>

              {selectedMembershipLevel?.id && (
                <Box mt={2}>
                  <MembershipRegionalSettings
                    membershipId={selectedMembershipLevel?.id as number}
                    parentId={selectedMembershipLevel.parentId}
                  />
                </Box>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!fk.isValid}
                color="primary"
                className={classes.submit}
              >
                {actionText}
              </Button>

              {selectedMembershipLevel?.id && (
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="default"
                  className={classes.submit}
                  onClick={() => dispatch(push(pageLinks.membershipSettings))}
                >
                  Go to Membership Levels
                </Button>
              )}
            </form>
          </div>
        )}
      </Paper>
    </Grid>
  );
};

export default CreateEditMembershipLevel;

const createRegionalSettingsFields = (
  fk: FormikProps<IValues>,
  type: string,
  handleSplitRenewFieldsChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: RECURRING_TYPES
  ) => void
) => {
  return (
    <>
      <Grid item xs={12}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography>{type} Settings:</Typography>
        </Box>
      </Grid>
      {[
        `splitBaseRenewValues.${type}`,
        `recurringPrices.${type}`,
        `spendings.${type}.min`,
        `spendings.${type}.max`,
        `pointRewards.${type}.rcv`,
        `pointRewards.${type}.rqv`,
        `pointRewards.${type}.rscv`,
        `pointRewards.${type}.rsqv`,
        `pointRewards.${type}.rfsb`,
        `pointRewards.${type}.rmatrixBV`,
      ].map((item) => {
        const itemArray = item.split('.');
        let label = itemArray[itemArray.length - 1]
          .replace('min', 'Min Spendings')
          .replace('max', 'Max Spendings');

        if (itemArray.length === 2) {
          label = itemArray[0]
            .replace('splitBaseRenewValues', 'Split Base Renew Value')
            .replace('recurringPrices', 'Recurring Price');
        }

        return (
          <Grid item xs={12} md={6} direction={'row'} alignItems={'center'}>
            <TextField
              key={item}
              variant="outlined"
              type={'number'}
              fullWidth
              id={item}
              label={label}
              required
              name={item}
              autoComplete="new-password"
              onBlur={fk.handleBlur}
              value={get(fk.values, item)}
              onChange={fk.handleChange}
            />
          </Grid>
        );
      })}
      {type !== RECURRING_TYPES.MONTHLY && (
        <>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              type="number"
              fullWidth
              label="Split Renew Values"
              name="splitRenewValues"
              onBlur={fk.handleBlur}
              onChange={(e) => handleSplitRenewFieldsChange(e, type as RECURRING_TYPES)}
              value={fk.values.splitRenewValues?.[type as RECURRING_TYPES]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              type="number"
              fullWidth
              label="Split Renew Periods"
              name="splitRenewPeriods"
              onBlur={fk.handleBlur}
              onChange={(e) => handleSplitRenewFieldsChange(e, type as RECURRING_TYPES)}
              value={fk.values.splitRenewPeriods?.[type as RECURRING_TYPES]}
            />
          </Grid>
        </>
      )}
    </>
  );
};

import { IFilterFields } from '@root/components/MembershipLevelsTable/types';
import { RegionalMembershipLevelDto } from '../../../components/MembershipRegionalSettings/types';
import { sanitizeObject } from '../../../utils/sanitize-object';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { RECURRING_TYPES } from "@root/enums/recurring-type";

export interface IPointRewards {
  rcv: number;
  rqv: number;
  rscv: number;
  rsqv: number;
  rfsb: number;
  rmatrixBV: number;
}

export interface IMembershipLevelPlans {
  id: number;
  name: string;
}

export interface IMembershipLevel {
  id?: number;
  name: string;
  color: string;
  imageUrl: string;
  iconUrl: string;
  memberName: string;
  isRegional: boolean;
  priority: number | string;
  addonsDiscount: number | string;
  plans: IMembershipLevelPlans[];
  spendings: Record<string, { min: number; max: number }>;
  recurringPrices: Record<string, number>;
  pointRewards: Record<string, IPointRewards>;
  splitBaseRenewValues?: Record<string, number>;
  parentId: number | null;
  splitRenewValues: Record<RECURRING_TYPES, number> | null;
  splitRenewPeriods: Record<RECURRING_TYPES, number> | null;
}

export interface IMembershipLevelResponse {
  data: IMembershipLevel[];
}

export interface ICreateMembershipLevel extends Omit<IMembershipLevel, 'id' | 'plans'> {
  planIds: number[];
}

export interface ICreateEditDeleteMembershipLevelResponse {
  data: IMembershipLevel;
}

export interface IEditMembershipLevelParams
  extends Partial<Omit<IMembershipLevel, 'id' | 'plans'>> {
  id: number | string;
  planIds: number[];
}

const readMembershipLevelList = (params?: IFilterFields) => {
  const query = new URLSearchParams();

  if (params) {
    Object.keys(params).forEach((key) => {
      const value = params[key as keyof IFilterFields];
      if (Array.isArray(value)) {
        value.forEach((item) => query.append(`${key}[]`, String(item)));
      } else if (value !== undefined) {
        query.append(key, String(value));
      }
    });
  }

  const queryString = query.toString().replace(/%5B/g, '[').replace(/%5D/g, ']');

  return apiClient
    .get<IMembershipLevelResponse>(
      `${ApiUrls.membershipLevelList}${params ? `?${queryString}` : ''}`
    )
    .then((response) => response);
};

const readMembershipLevelItem = (id: number | string) => {
  return apiClient
    .get<ICreateEditDeleteMembershipLevelResponse>(ApiUrls.membershipLevel + `${id}`)
    .then((response) => response);
};

const createMembershipLevelItem = (data: ICreateMembershipLevel) => {
  return apiClient
    .post<ICreateEditDeleteMembershipLevelResponse>(
      ApiUrls.createMembershipLevel,
      sanitizeObject(data)
    )
    .then((response) => response);
};

const editMembershipLevelItem = (data: IEditMembershipLevelParams) => {
  const { splitRenewPeriods, splitRenewValues } = data;
  const sanitizeData = sanitizeObject(data);
  return apiClient
  .put<ICreateEditDeleteMembershipLevelResponse>(
    ApiUrls.editMembershipLevel + String(data.parentId || data.id),
    {...sanitizeData, splitRenewPeriods, splitRenewValues}
  )
  .then((response) => response);
}

const deleteMembershipLevelItem = (id: string | number) => {
  return apiClient
    .delete<ICreateEditDeleteMembershipLevelResponse>(ApiUrls.deleteMembershipLevel + `${id}`)
    .then((response) => response);
};

//! REGIONAL SETTINGS
const createMembershipLevelRegionalSettings = (
  id: string | number,
  data: RegionalMembershipLevelDto[]
) => {
  return apiClient
    .post<{ data: RegionalMembershipLevelDto }>(
      ApiUrls.membershipRegionalSettings.replace('{id}', id as string),
      data
    )

    .then((response) => response);
};

const getMembershipLevelRegionalSettings = (id: string | number) => {
  return apiClient
    .get<{
      data: Array<RegionalMembershipLevelDto>;
    }>(ApiUrls.membershipRegionalSettings.replace('{id}', id as string))
    .then((response) => response);
};

const deleteMembershipLevelRegionalSetting = (membershipId: string | number, settingId: number) => {
  return apiClient
    .delete<ICreateEditDeleteMembershipLevelResponse>(
      ApiUrls.membershipRegionalSetting.replace('{id}', membershipId as string) + settingId
    )
    .then((response) => response);
};

const editMembershipLevelRegionalSetting = (
  membershipId: string | number,
  settingId: number,
  data: RegionalMembershipLevelDto,
  parentId: string
) => {
  return apiClient
    .put<{
      data: RegionalMembershipLevelDto;
    }>(
      ApiUrls.membershipRegionalSetting.replace('{id}', parentId || (membershipId as string)) +
        settingId,
      data
    )
    .then((response) => response);
};

const membershipLevelRequests = {
  readMembershipLevelList,
  readMembershipLevelItem,
  createMembershipLevelItem,
  editMembershipLevelItem,
  deleteMembershipLevelItem,
  getMembershipLevelRegionalSettings,
  createMembershipLevelRegionalSettings,
  deleteMembershipLevelRegionalSetting,
  editMembershipLevelRegionalSetting,
};

export default membershipLevelRequests;

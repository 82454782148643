import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormikProps } from 'formik';
import Image from 'material-ui-image';
import * as React from 'react';
import { useState } from 'react';
import {
  IMAGE_MIME_TYPES,
  IMAGE_UPLOAD_ENTITY,
  LANGUAGE_OPTIONS,
  VIDEO_MIME_TYPES,
} from '../../constants';
import deleteAttachment from '../../services/api/requests/deleteAttachment';
import { IAttachment } from '../../services/api/requests/editProductPlan';
import uploadAttachment from '../../services/api/requests/uploadAttachment';
import uploadPicture from '../../services/api/requests/uploadPicture';
import { onAddVideo } from '../../utils/helpers';
import InputSelect from '../InputSelect';

interface IProps {
  list: IAttachment[] | [];
  label: string;
  fk: FormikProps<any>;
  id: string;
  isVideoInput: boolean;
  videos: IAttachment[] | [];
  images: IAttachment[] | [];
}

const videoMimeTypes = VIDEO_MIME_TYPES.join(',');
const imageMimeTypes = IMAGE_MIME_TYPES.join(',');

const MultipleFileInput: React.FC<IProps> = (props: IProps) => {
  const { list, label, fk, id, isVideoInput, videos, images } = props;
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoLanguage, setVideoLanguage] = useState<string | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [videoInputEvent, setVideoInputEvent] =
    useState<React.ChangeEvent<HTMLInputElement> | null>(null);

  const fieldId = id || 'file';

  const attachmentList = [...videos, ...images];

  const cleanInput = () => {
    setVideoLanguage(null);
    setVideoUrl(null);

    if (videoInputEvent) {
      videoInputEvent.target.value = '';
    }

    setVideoInputEvent(null);
  };

  const deleteAttachmentFile = async (attachment: IAttachment) => {
    try {
      const updatedAttachments = fk.values.attachments?.filter(
        (item: IAttachment) => item.id !== attachment.id
      );
      await deleteAttachment(attachment.id);
      fk.setFieldValue(fieldId, updatedAttachments);
    } catch (e) {
      console.warn('delete attachment error', e);
    }
  };

  const uploadVideo = () => {
    if (videoLanguage && videoUrl && previewUrl) {
      onAddVideo({
        videoUrl,
        language: videoLanguage,
        fk,
        id,
        list,
        previewUrl,
        cleanVideoInputs: cleanInput,
      });
    }
  };

  const uploadToS3 = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.currentTarget.files?.[0];
      const imageUrl = await uploadPicture({
        entity: IMAGE_UPLOAD_ENTITY.PLAN,
        mimetype: file?.type,
        file: e.currentTarget.files?.[0],
      });

      if (imageUrl && file) {
        return {
          url: imageUrl,
          type: file.type,
          size: file.size,
          name: file.name,
        };
      }
    } catch (e) {
      fk.setFieldError(id, 'An error occurred while uploading');
    }
  };

  const handleAddVideoPreview = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const imageData = await uploadToS3(e);
      setPreviewUrl(imageData?.url || null);
    } catch (e) {
      fk.setFieldError(id, 'An error occurred while uploading video');
    } finally {
      e.target.value = '';
    }
  };

  const handleAddImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const imageData = await uploadToS3(e);

      if (imageData) {
        const data = {
          ...imageData,
          language: 'multi',
        };

        const response = await uploadAttachment({ items: [data] });
        response.data.data && fk.setFieldValue(id, [...response.data.data, ...list]);
      }
    } catch (e) {
      fk.setFieldError(id, 'An error occurred while uploading video');
    } finally {
      e.target.value = '';
    }
  };

  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <Grid container>
        {attachmentList?.map((item: IAttachment) => (
          <Grid key={item.id} item xs={6} md={6} lg={6}>
            <Box display={'flex'} alignItems={'center'}>
              <Box display={'flex-col'}>
                <Image
                  src={item?.previewUrl || item.url}
                  aspectRatio={25 / 9}
                  style={{ width: 200, height: 100 }}
                  disableSpinner
                />
                {!!item.language && !!videos.length && (
                  <Typography>language: {item.language}</Typography>
                )}
              </Box>

              <Box width={30} height={30}>
                <IconButton
                  aria-label="delete"
                  title={'Delete'}
                  onClick={() => deleteAttachmentFile(item)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      {isVideoInput ? (
        <div>
          <Grid item xs={12} md={12}>
            <InputSelect
              label={'Choose a language'}
              value={videoLanguage}
              onChange={(e: React.ChangeEvent<any>): void => setVideoLanguage(e.target.value)}
              options={LANGUAGE_OPTIONS}
            />
          </Grid>

          <div>{'Select image as video preview'}</div>
          <TextField
            variant="outlined"
            fullWidth
            type={'file'}
            id={fieldId}
            name={fieldId}
            autoComplete="new-password"
            error={!!(fk.errors[fieldId] && fk.touched[fieldId])}
            onBlur={fk.handleBlur}
            helperText={fk.touched[fieldId] && fk.errors[fieldId]}
            onChange={handleAddVideoPreview}
            inputProps={{ accept: imageMimeTypes, multiple: false }}
            disabled={!videoLanguage}
          />

          <div>{'Enter a Vimeo video url'}</div>

          <TextField
            variant="outlined"
            fullWidth
            id={fieldId}
            name={fieldId}
            autoComplete="new-password"
            error={!!(fk.errors[fieldId] && fk.touched[fieldId])}
            onBlur={fk.handleBlur}
            helperText={fk.touched[fieldId] && fk.errors[fieldId]}
            onChange={(e) => {
              setVideoUrl(e.target.value);
              !videoInputEvent && setVideoInputEvent(e as React.ChangeEvent<HTMLInputElement>);
            }}
            inputProps={{ accept: videoMimeTypes, multiple: false, value: videoUrl }}
            disabled={!videoLanguage || !previewUrl}
          />

          <div style={{ marginBottom: 10 }} />

          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={uploadVideo}
            disabled={!videoLanguage || !previewUrl || !videoUrl?.length}
          >
            Save video
          </Button>
        </div>
      ) : (
        <TextField
          variant="outlined"
          fullWidth
          type={'file'}
          id={fieldId}
          name={fieldId}
          autoComplete="new-password"
          error={!!(fk.errors[fieldId] && fk.touched[fieldId])}
          onBlur={fk.handleBlur}
          helperText={fk.touched[fieldId] && fk.errors[fieldId]}
          value={fk.values[fieldId]?.fileName}
          // onChange={(e) => onAddMultipleMedia({ e, fk, id, list })}

          onChange={handleAddImage}
          inputProps={{ accept: imageMimeTypes, multiple: false }}
        />
      )}
    </div>
  );
};

export default MultipleFileInput;

import { RECURRING_TYPES } from "@root/enums/recurring-type";
import { IValues } from './types';

export const initialValues: IValues = {
  name: '',
  color: '#000',
  imageUrl: '',
  iconUrl: '',
  memberName: '',
  priority: 1,
  addonsDiscount: 0,
  planIds: [],
  spendings: {},
  recurringPrices: {},
  pointRewards: {},
  splitBaseRenewValues: {},
  isRegional: false,
  parentId: null,
  splitRenewValues: {} as Record<RECURRING_TYPES, number>,
  splitRenewPeriods: {} as Record<RECURRING_TYPES, number>,
};
